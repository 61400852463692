<template>
  <div>
    <h3 class="text-center">Partners</h3>
    <b-button variant="outline-dark" to="add_partners"
      ><b-icon icon="plus"></b-icon>Add</b-button
    >
    
    <b-row>
            <p class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem"
          v-if="!items"
          label="Large Spinner"
        ></b-spinner>
      </p>

      <p
        class="text-center"
        style="color: red"
        v-if="items && items.length == 0"
      >
        there is no data
      </p>
      <b-col md="3" v-for="(item, index) in items" :key="index">
        <b-card
          :title="Date(item.date)"
          :img-src="item.imageUrl"
          img-alt="Image"
          img-top
          tag="article"
          style="max-width: 20rem"
          class="mb-2 news-card"
        >
         

          <div class="text-center">
            <b-button
              variant="danger"
              style="float: left"
              @click="globalDelete('Partners', item.id)"
            >
              <b-spinner
                style="width: 5rem; height: 5rem"
                v-if="loading"
              ></b-spinner>
              Delete</b-button
            >
            <b-button variant="outline-dark" style="float: right;"
    
     @click="routeTo('edit', item)"
      ><b-icon icon="pencil"></b-icon>Edit</b-button
    >
           
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-pagination
      @input="pageChange(currentPage)"
      v-model="currentPage"
      v-if="!filterdData"
      align="center"
      :total-rows="rows"
      :per-page="perPage"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    ></b-pagination>
  </div>
</template>
<script>
const {imageToBanner,getFields,postData,getFieldsPage,getPerPage} = require("../../assets/js/service")
export default {
  data() {
    return {
      fromDate: null,
      toDate: null,
      currentPage: 1,
      rows: 1,
      perPage: 1,
      loading:false,
      filterdData:false,
      isBusy: false,
      items: null
    };
  },
  watch:{
      "$store.state.viewReload": {
      handler() {
        if (this.$store.state.viewReload) {
          this.onCreateLoading();
          this.$store.commit("set", ["viewReload", false]);
        }
      },
    },
  },
  mounted() {
    this.onCreateLoading();

    
  },
  methods: {
    onCreateLoading(){
      let token = localStorage.getItem('token');
    getFields('Partners/count',token).then(resp=>{
      this.rows = Math.ceil(resp.data.count / getPerPage());
      getFieldsPage('Partners',1,token).then(resp=>{
 this.items = resp.data;
      })
    })
    },
    pageChange(currentPage){
      let token = localStorage.getItem('token');
      getFieldsPage('Partners',currentPage,token).then(resp=>{
 this.items = resp.data;
      })
    },
    filterByDate(){
      this.filterdData = true;
      let token = localStorage.getItem('token');
      postData('news/filterByDate',{toDate:this.toDate,fromDate:this.fromDate},token).then(resp=>{
    
        this.items = resp.data.Data;
      })
    },
    routeTo(page, data) {
      this.$store.commit("set", ["singlePageData", data]);
      this.$router.push(page);
    },
     makeBanorimage(img) {
      return imageToBanner("lowQuality", img);
    },
  },
};
</script>
<style>
</style>